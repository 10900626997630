import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PolicyDialog = ({open, handleClose, policy}) => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const returnPolicy = 'I agree that I am customizing a product to my own liking and The Shirt Shop reserves the right to refuse any returns on custom made products.'
    const textingPolicy = 'By providing your phone number and agreeing to receive text messages, you consent to receive automated informational texts from The Shirt Shop. Standard message and data rates may apply.'

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            onClose={handleClose}
        >
            <DialogTitle id='dialog-title'>
                {(policy === 'return') ? 'Return Policy' : 'Texting Policy'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText gutterBottom>
                    {(policy === 'return') ? returnPolicy : textingPolicy}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PolicyDialog