// extract digits from a string
export const extractDigits = (value) => {
    if (!value) return value
    return value.replace(/\D/g, '')
}

// format a string as a phone number
export const formatPhone = (value) => {
    if (!value) return value
    const phoneNum = value.replace(/\D/g, '')
    const length = phoneNum.length
    if (length < 4) return phoneNum
    if (length < 7) {
        return `(${phoneNum.slice(0,3)}) ${phoneNum.slice(3)}`
    }
    if (length === 7) {
        return `${phoneNum.slice(0,3)}-${phoneNum.slice(3)}`
    }
    return `(${phoneNum.slice(0,3)}) ${phoneNum.slice(3,6)}-${phoneNum.slice(6,10)}`
}