import * as React from 'react';
import {IconButton, InputAdornment} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const ResetBtn = ({setFxn, prop}) => {

    return (
        <InputAdornment position='end'>
            <IconButton
                onClick={() => setFxn(prop)}
                edge='end'
                disableRipple
            >
                <RestartAltIcon/>
            </IconButton>
        </InputAdornment>
    )
}

export default ResetBtn