import * as React from 'react';
import {useNavigate, useParams} from "react-router";
import {useEffect, useState, Fragment} from "react";
import {getItem} from "../../api";
import {Button, Grid, Box, LinearProgress, Divider, ListItem, List, ListItemText, Typography} from "@mui/material";
import {formatPhone} from "../../utils/number";

const ViewOrder = () => {
    const navigate = useNavigate();
    const {orderKey} = useParams();
    const [order, setOrder] = useState();

    useEffect(() => {
        orderKey && getItem(orderKey)
            .then(setOrder)
            .catch(alert)
    }, [orderKey])

    return (
        <>
            {!order &&
                <>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </>
            }
            <Grid item xs={12} py={1}>
                <Button onClick={() => navigate('/orders')}>Go Back</Button>
            </Grid>
            {order &&
                <>
                    <Button onClick={() => navigate(`/orders/edit/${orderKey}`)}>edit order</Button>

                    <h3>Order {orderKey}</h3>
                    <p>{order.fulfilled ? 'fulfilled' : 'unfulfilled'}</p>
                    <p>{order.paidStatus ? 'paid' : 'unpaid'}</p>
                    <p>Date: {order.orderDate}</p>
                    <p>Name: {order.name}</p>
                    <p>Phone: {formatPhone(order.phone)}</p>
                    <p>Pickup method: {order.pickupMethod}</p>
                    <p>Pickup date: {order.pickupDate ? order.pickupDate : 'n/a'}</p>
                    <p>Shipping address: {order.shipAddress ? order.shipAddress : 'n/a'}</p>
                    <p>Additional notes: {order.notes ? order.notes : 'n/a'}</p>

                    <Grid container sx={{justifyContent:'center'}}>
                        <List sx={{width: '500px'}}>
                            {
                                order['products'].map((item, index) => (
                                    <Fragment key={index}>
                                        <Divider/>
                                        <ListItem>
                                            <ListItemText
                                                disableTypography
                                            >
                                                <Typography>({index+1}) <em>{item.product}</em> - {item.size}</Typography>
                                                {
                                                    item['logos'].map((logo, i) => (
                                                        <Fragment key={i}>
                                                            <Typography variant={'body2'}>{i+1}) logo: {logo.logo}</Typography>
                                                            <Typography variant={'body2'}>location: {logo.location}</Typography>
                                                            <Typography variant={'body2'}>color: {logo.color}</Typography>
                                                        </Fragment>
                                                    ))
                                                }
                                            </ListItemText>
                                        </ListItem>
                                    </Fragment>
                                ))
                            }
                        </List>
                    </Grid>
                </>
            }
        </>
    )
}

export default ViewOrder