import {useRoutes} from 'react-router-dom';
import OrderForm from "../pages/order-form";
import OrdersTable from "../pages/orders-table";
import Home from "../pages/home/Home";
import EditOrder from "../pages/edit-order";
import ViewOrder from "../pages/view-order";

const routes = {
  path: '/',
  children: [
    {
      path: '/',
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: 'orders',
          children: [
            {
              path: '',
              element: <OrdersTable/>,
            },
            {
              path: 'create',
              element: <OrderForm/>
            },
            {
              path: ':orderKey',
              element: <ViewOrder/>
            },
            {
              path: 'edit',
              children: [
                {
                  path: ':orderKey',
                  element: <EditOrder/>
                },
              ]
            },
          ]
        },
      ]
    },
  ]
}

export default function MyRoutes() {
  return useRoutes([routes]);
}
