import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useNavigate} from "react-router";
import {Button, LinearProgress, Snackbar, Chip} from "@mui/material";
import NoDataOverlay from "./NoDataOverlay";
import CustomToolbar from "./Toolbar";
import {formatPhone} from "../../../utils/number";

export default function OrdersGrid ({ data, isLoading }) {
    const navigate = useNavigate()

    const [selectedRows, setSelectedRows] = React.useState([])
    const [open, setOpen] = React.useState(true)

    const rows = data?.map((row) => ({
        ...row,
        id: row.key,
        phone: formatPhone(row.phone)
    }));

    const options = {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    const fulfillmentChip = (props) => {
        const { value } = props

        return (
            value ? <Chip label={'fulfilled'} size='small' color='success'/> : <Chip label={'unfulfilled'} size='small' color='warning'/>
        )
    }

    const cols= [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.2,
        },
        {
            type: 'dateTime',
            field: 'orderDate',
            headerName: 'Order Date',
            flex: 0.4,
            valueGetter: ({value}) => value && new Date(value),
            valueFormatter: ({value}) => {
                if (value == null) {
                    return '';
                }
                return `${value.toLocaleString('en-US', options)}`;
            }
        },
        { field: 'name', headerName: 'Name', flex: 0.35 },
        { field: 'phone', headerName: 'Phone', flex: 0.35 },
        {
            field: 'fulfilled',
            headerName: 'Fulfillment',
            flex: 0.35,
            renderCell: fulfillmentChip
        },
        {
            field: 'products',
            headerName: 'Items',
            flex: 0.35,
            valueGetter: ({value}) => value.length,
            valueFormatter: ({value}) => {
                if (value == null) {
                    return '';
                }
                return `${value} items`
            }
        },
    ];


    return (
        <div style={{ width: '97%', minHeight: '20rem' }}>
            {(selectedRows.length !== 0) &&
                <Snackbar
                    open={open}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                >
                    <Button variant={'contained'} onClick={() => console.log(selectedRows)}>mark as fulfilled</Button>
                </Snackbar>
            }

            <DataGrid
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedRows(newSelectionModel);
                }}
                rowSelectionModel={selectedRows}
                density={'compact'}
                columns={cols}
                rows={rows}
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: NoDataOverlay,
                }}
                loading={isLoading}
                onRowClick={(params) => navigate(`/orders/${params.id}`)}
                initialState={{
                    ...data.initialState,
                    sorting: {
                        ...data.initialState?.sorting,
                        sortModel: [
                            {
                                field: 'orderDate',
                                sort: 'desc',
                            },
                        ],
                    },
                }}
            />
        </div>
    )
}