import './App.css';
import MyRoutes from "./routes/MyRoutes";
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from "@mui/material";
import {theme1} from "./utils/theme";

export default function App() {
  return (
    <div className="App">
        <ThemeProvider theme={theme1}>
            <BrowserRouter>
                <MyRoutes />
            </BrowserRouter>
        </ThemeProvider>
    </div>
  );
}
