import {createTheme} from "@mui/material";

export const theme1= createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#14361d',
        },
        secondary: {
            main: '#223757',
        },
        background: {
            default: '#5d6e5d',
            paper: '#ffffff',
        },
    },
})