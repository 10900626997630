import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {extractDigits, formatPhone} from "../../../../utils/number";
import ClearIcon from "@mui/icons-material/Clear";
import * as React from "react";

const CustomerPanel = ({order, handleChange, formattedPhone, setFormattedPhone}) => {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                id='custInfo-header'
            >
                Customer Info
            </AccordionSummary>
            <Divider/>
            <AccordionDetails>
                <Grid container spacing={1.5} px={1} py={3}>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id='paidStatus'
                                        checked={order?.paidStatus}
                                        onChange={e => handleChange(e.target.id, e.target.checked)}
                                    />
                                }
                                label='Paid'
                                labelPlacement='start'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{width: '50%'}}>
                            <InputLabel htmlFor='name'>Customer Name</InputLabel>
                            <Input
                                id='name'
                                value={order?.name ?? ''}
                                onChange={e => handleChange(e.target.id, e.target.value)}
                                inputProps={{maxLength: 30}}
                                endAdornment={(order?.name ?? '' !== '') &&
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => handleChange('name', '')}
                                            edge='end'
                                            disableRipple
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{width: '50%'}}>
                            <InputLabel htmlFor='phone'>Phone Number</InputLabel>
                            <Input
                                id='phone'
                                type='tel'
                                inputMode='numeric'
                                pattern='[0-9]*'
                                inputProps={{ maxLength: 14 }}
                                value={formattedPhone ?? ''}
                                onChange={e => {
                                    handleChange(e.target.id, extractDigits(e.target.value));
                                    setFormattedPhone(formatPhone(e.target.value))
                                }}
                                endAdornment={(order.phone ?? '' !== '') &&
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                handleChange('phone', '');
                                                setFormattedPhone('')
                                            }}
                                            edge="end"
                                            disableRipple
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{width: '50%'}}>
                            <InputLabel htmlFor='pickupDate'>Pick-up Date (optional)</InputLabel>
                            <Input
                                id='pickupDate'
                                value={order?.pickupDate ?? ''}
                                inputProps={{maxLength: 15}}
                                onChange={e => handleChange(e.target.id, e.target.value)}
                                endAdornment={(order?.pickupDate ?? '' !== '') &&
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => handleChange('pickupDate', '')}
                                            edge='end'
                                            disableRipple
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant='standard' sx={{width: '50%'}} size='small'>
                            <InputLabel htmlFor='pickupMethod-select'>
                                Pickup Method
                            </InputLabel>
                            <Select
                                id='pickupMethod'
                                defaultValue={order?.pickupMethod}
                                value={order?.pickupMethod}
                                onChange={e => {
                                    handleChange('pickupMethod', e.target.value)
                                }}
                            >
                                <MenuItem value={'steam'}>Steam</MenuItem>
                                <MenuItem value={'bag'}>Bag</MenuItem>
                                <MenuItem value={'wrap'}>Gift Wrap</MenuItem>
                                <MenuItem value={'n/a'}>N/A</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='shipAddress'
                            label='Shipping Address'
                            multiline
                            rows={3}
                            sx={{width: '75%'}}
                            value={order?.shipAddress ?? ''}
                            inputProps={{maxLength: 100}}
                            onChange={e => handleChange(e.target.id, e.target.value)}
                            InputProps={{
                                endAdornment: ((order.shipAddress ?? '' !== '') &&
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => handleChange('shipAddress', '')}
                                            edge='end'
                                            disableRipple
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
export default CustomerPanel;