import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    IconButton
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import LogoSelectForm from "./logoSelect-form";
import {useState} from "react";
import useLocalStorage from "../../../../hooks/useLocalStorage";

const LogoSelect = ({open, handleClose, logosArray, handleLogosArrayChange, handleProductChange}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const [logoObj, setLogoObj] = useLocalStorage('logoObj', {})

    const handleLogoChange = (id, value) => {
        setLogoObj({...logoObj, [id]: value})
    }

    const handleAdd = () => {
        handleLogosArrayChange(logoObj)
        handleProductChange('logos', [...logosArray, logoObj])
        setLogoObj({})
        handleClose()
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
            onClose={handleClose}
        >
            <DialogTitle id='dialog-title'>
                Logo selection
            </DialogTitle>
            <IconButton
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    Select logo, location, and thread color.
                </DialogContentText>

                <LogoSelectForm
                    logoObj={logoObj}
                    handleLogoChange={handleLogoChange}
                />
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center'}}>
                {((logoObj?.logo ?? false) && (logoObj?.location ?? false) && (logoObj?.color ?? false)) &&
                    <Button variant='contained' sx={{width: '80%'}} onClick={() => {handleAdd()}}>add</Button>}
                {((!logoObj?.logo ?? false) || (!logoObj?.location ?? false) || (!logoObj?.color ?? false)) &&
                    <Button variant='contained' sx={{width: '80%'}} disabled>add</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default LogoSelect