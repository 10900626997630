import * as React from 'react';
import { Fragment } from 'react';
import {
    List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Divider, IconButton, Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from '@mui/icons-material/Delete';

const ProductList = ({productsArray, setProductsArray}) => {

    const RemoveItem = (i) => {
        setProductsArray(productsArray => productsArray.filter((item, index) => i !== index))
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                Order
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {productsArray?.map((item, index) => (
                        <Fragment key={index}>
                            <Divider/>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={()=> RemoveItem(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    disableTypography
                                >
                                    <Typography>({index+1}) <em>{item.product}</em> - {item.size}</Typography>
                                    {
                                        item['logos'].map((logo, i) => (
                                            <Fragment key={i}>
                                                <Typography variant={'body2'}>{i+1}) logo: {logo.logo}</Typography>
                                                <Typography variant={'body2'}>location: {logo.location}</Typography>
                                                <Typography variant={'body2'}>color: {logo.color}</Typography>
                                            </Fragment>
                                        ))
                                    }
                                </ListItemText>
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default ProductList