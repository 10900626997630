import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    LinearProgress,
    TextField,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router';
import {getItem} from '../../api';
import ResetBtn from "./components/reset-btn";

const EditOrder = () => {
    const navigate = useNavigate()
    const {orderKey} = useParams()
    const [order, setOrder] = useState()

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    const setProps = () => {
        setName(order['name'])
        setPhone(order['phone'])
    }

    useEffect(() => {
        orderKey && getItem(orderKey)
            .then(setOrder)
            .catch(alert)
    }, [orderKey])

    useEffect(() => {
        order && setProps()
    }, [order])

    return (
        <>
            {!order &&
                <>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </>
            }
            <Grid item xs={12} py={1}>
                <Button onClick={() => navigate(`/orders/${orderKey}`)}>Go Back</Button>
            </Grid>

            {order &&
                <>
                    {order['fulfilled'] &&
                        <Alert sx={{justifyContent: 'center'}} severity='warning'>This order has already been fulfilled. It is not editable.</Alert>
                    }
                    <h3>Edit order {orderKey}</h3>

                    {!order['fulfilled'] &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl sx={{width: '30%'}}>
                                    <TextField
                                        id='name'
                                        label='Customer Name'
                                        variant='outlined'
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        InputProps={{
                                            endAdornment: <ResetBtn setFxn={setName} prop={order['name']}/>,
                                        }}
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl sx={{width: '30%'}}>
                                    <TextField
                                        id='phone'
                                        label='Phone Number'
                                        variant='outlined'
                                        type='tel'
                                        value={phone}
                                        onChange={e => setPhone(e.target.value.replace(/\D/g, ''))}
                                        InputProps={{
                                            endAdornment: <ResetBtn setFxn={setPhone} prop={order['phone']}/>,
                                            inputMode:'numeric',
                                        }}
                                        inputProps={{ maxLength: 10, pattern:'[0-9]*' }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Button onClick={() => console.log(order)}>Save Changes</Button>
                            </Grid>
                        </Grid>
                    }
                </>
            }
        </>
    )
}

export default EditOrder;