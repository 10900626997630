import * as React from "react";
import {
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl, InputLabel, Input, Button, MenuItem, Select, Divider, InputAdornment, IconButton, Stack
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import {useEffect, useState} from "react";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import LogoSelect from "./logoSelect-dialog";
import ProductList from "./product-list";
import LogoList from "./logo-list";

const ProductPanel = ({handleChange, productsArray, setProductsArray}) => {

    const [productObj, setProductObj] = useLocalStorage('productObj', {})
    const [logosArray, setLogosArray] = useLocalStorage('logosArray', [])

    const [open, setOpen] = React.useState(false)

    const handleProductsArrayChange = (obj) => {
        setProductsArray([...productsArray, obj])
    }
    const handleProductChange = (id, value) => {
        setProductObj({...productObj, [id]: value})
    }
    const handleLogosArrayChange = (obj) => {
        setLogosArray([...logosArray, obj])
    }

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        setOpen(false)
    }

    const handleAddProduct = () => {
        handleProductsArrayChange(productObj);
        handleChange('products', [...productsArray, productObj]);
        setProductObj({});
        setLogosArray([]);
    }

    useEffect(() => {
        handleProductChange('logos', logosArray)
    }, [logosArray]);
    useEffect(() => {
        handleChange('products', productsArray)
    }, [productsArray]);

    return (
        <>
            {open &&
                <LogoSelect
                    open={open}
                    handleClose={handleClose}
                    logosArray={logosArray}
                    handleLogosArrayChange={handleLogosArrayChange}
                    handleProductChange={handleProductChange}
                />
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id='productInfo-header'
                >
                    Products
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container py={3}>
                                <Grid item xs={8}>
                                    <FormControl sx={{width: '75%'}}>
                                        <InputLabel htmlFor='product'>Product Name</InputLabel>
                                        <Input
                                            id='product'
                                            value={productObj?.product ?? ''}
                                            inputProps={{maxLength: 30}}
                                            onChange={e => handleProductChange(e.target.id, e.target.value)}
                                            endAdornment={(productObj?.product ?? '' !== '') &&
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleProductChange('product', '');
                                                        }}
                                                        edge="end"
                                                        disableRipple
                                                    >
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant='standard' sx={{width: '50%'}} size='small'>
                                        <InputLabel htmlFor='size'>
                                            Size
                                        </InputLabel>
                                        <Select
                                            id='size'
                                            value={productObj?.size ?? ''}
                                            defaultValue={productObj?.size ?? ''}
                                            onChange={e => {
                                                handleProductChange('size', e.target.value)
                                            }}
                                        >
                                            <MenuItem value={'n/a'}><em>N/A</em></MenuItem>
                                            <MenuItem value={'S'}>S</MenuItem>
                                            <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'L'}>L</MenuItem>
                                            <MenuItem value={'XL'}>XL</MenuItem>
                                            <MenuItem value={'2XL'}>2XL</MenuItem>
                                            <MenuItem value={'3XL'}>3XL</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container py={5} sx={{justifyContent: 'center'}}>
                                        <Button variant='outlined' sx={{width: '85%'}} onClick={() => handleClickOpen()}>Add logo</Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container py={3} sx={{justifyContent: 'center'}}>
                                        <Divider sx={{width: '85%'}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container sx={{justifyContent: 'center'}}>
                                <Stack sx={{width: '80%'}}>
                                    <LogoList logosArray={logosArray} setLogosArray={setLogosArray}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container py={5} sx={{justifyContent: 'center'}}>
                        {((productObj?.product ?? false) && (productObj?.size ?? false)) &&
                            <Button variant={'contained'} sx={{width: '85%'}} onClick={() => {handleAddProduct()}}>add this product to order</Button>
                        }
                        {((!productObj?.product ?? false) || (!productObj?.size ?? false)) &&
                            <Button variant={'contained'} sx={{width: '85%'}} disabled>add this product to order</Button>
                        }
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <ProductList
                                productsArray={productsArray}
                                setProductsArray={setProductsArray}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ProductPanel