import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Button} from "@mui/material";

const Refresh = () => {

    return (
        <Button variant="text" onClick={() => console.log('click')}><RefreshIcon /></Button>
    )
}

const CustomToolbar = () => {

    return (
        <GridToolbarContainer>
            <Refresh />
            <GridToolbarColumnsButton />
            <GridToolbarExport />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    )
}

export default CustomToolbar