import * as React from "react";
import dayjs from 'dayjs';
import OrdersGrid from "./components/DataGrid";
import {useEffect, useState} from "react";
import {queryOrders} from "../../api";
import {Button, Grid, Box, Typography, Stack} from "@mui/material";
import {useNavigate} from "react-router";
import TopLevelFilter from "./components/TopLevelFilter";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const OrdersTable = () => {
    const navigate = useNavigate();

    const currentDate = new Date().toISOString()
    const [ orders, setOrders ] = useState([]);
    const [ startDate, setStartDate ] = useState('2023-08-01')
    const [ endDate, setEndDate ] = useState(dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD'))

    const [ loading, setLoading ] = useState(true)

    function handleQuery (startDate, endDate) {
        queryOrders(startDate, endDate)
            .then(setOrders)
            .catch(alert)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleQuery(startDate, endDate)
    }, [])

    return (
        <Grid >
            <Stack
                direction={'row'}
                spacing={3}
                px={2}
                py={4}
                justifyContent='flex-start'
                alignItems='center'
            >
                <Button startIcon={<ChevronLeftIcon/>} onClick={() => navigate('/')}>Go Back</Button>
                <Typography variant={'h4'}>Orders</Typography>
            </Stack>

            <Grid item py={2}>
                <Grid container sx={{justifyContent: 'center'}}>
                    <Grid item sx={{width: '97%'}}>
                        <TopLevelFilter
                            currentDate={currentDate}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            handleQuery={handleQuery}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <OrdersGrid data={orders} isLoading={loading}/>
            </Box>
        </Grid>
    )
}

export default OrdersTable;