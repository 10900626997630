import * as React from "react";
import {Fragment, useEffect} from 'react';
import {
    List, ListItem, ListItemText, Divider, IconButton
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const LogoList = ({logosArray, setLogosArray, handleLogoRemove}) => {

    const RemoveItem = (index) => {
        setLogosArray(logosArray => logosArray.filter((item, i) => i !== index));

    }

    return (
        <List>
            {logosArray?.map((item, index) => (
                <Fragment key={index}>
                    <ListItem
                        secondaryAction={
                            <IconButton
                                edge="end"
                                onClick={()=> RemoveItem(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={item.logo} secondary={item.location + ', ' + item.color} />
                    </ListItem>
                    <Divider/>
                </Fragment>
            ))}
        </List>
    )
}

export default LogoList