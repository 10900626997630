import * as React from "react";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AccordionSummary, Typography, AccordionDetails, Accordion, Button, Alert, Collapse, IconButton, Grid } from "@mui/material/";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TopLevelFilter = ({currentDate, startDate, endDate, setStartDate, setEndDate, handleQuery}) => {

    const [startValue, setStartValue] = React.useState(dayjs(startDate));
    const [endValue, setEndValue] = React.useState(dayjs(endDate));

    const [dateError, setDateError] = React.useState(false)
    // const [open, setOpen] = React.useState(true)

    const resetDates = () => {
        setEndValue(dayjs(currentDate).add(1, 'day'))
        setStartValue(dayjs('2023-08-01'))
    }

    const convertDates = () => {

        if (dayjs(startValue) > dayjs(endValue) || dayjs(endValue) < dayjs(startValue)) {
            setDateError(true)
            resetDates()
        } else {
            setStartDate(dayjs(startValue).format('YYYY-MM-DD'))
            setEndDate(dayjs(endValue).add(1, 'day').format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        convertDates()
    }, [startValue,endValue]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={{width: '5%'}}>
                    Filters
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container sx={{justifyContent: 'left'}}>
                        <Grid item xs={3} components={['DatePicker', 'DatePicker']} px={2}>
                            <DatePicker
                                label="Start Date"
                                value={startValue}
                                onChange={(newValue) => setStartValue(newValue)}
                            />
                        </Grid>
                        <Grid item xs={3} components={['DatePicker', 'DatePicker']} px={2}>
                            <DatePicker
                                label="End Date"
                                value={endValue}
                                onChange={(newValue) => setEndValue(newValue)}
                            />
                        </Grid>
                        <Grid item >
                            <Button onClick={() => resetDates()}>reset</Button>
                        </Grid>
                        {dateError &&
                            <Collapse in={dateError}>
                                <Alert
                                    severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setDateError(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    Invalid date range. Please retry.
                                </Alert>
                            </Collapse>
                        }
                        <Grid item xs={12} py={2} px={2}>
                            <Button variant="outlined" sx={{width: '100%'}} onClick={() => handleQuery(startDate,endDate)}>Apply Filters</Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </AccordionDetails>
        </Accordion>
    )
}

export default TopLevelFilter