import * as React from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {createOrder} from "../../api";
import ProductPanel from "./components/product-panel";
import AcknowlPanel from "./components/acknowl-panel";
import NotesInput from "./components/notes-input";
import OrderAlert from "./components/order-alert";
import useLocalStorage from "../../hooks/useLocalStorage";
import CustomerPanel from "./components/customer-panel/index.js";

const defaultOrder = {
    pickupMethod: 'steam',
    products: [],
    paidStatus: true,
    returnPolicy: false,
    textPolicy: false,
}

const OrderForm = () => {
    const navigate = useNavigate()

    const [order, setOrder] = useLocalStorage('order', {...defaultOrder})
    const [completedOrder, setCompletedOrder] = useState(false)
    const [productsArray, setProductsArray] = useLocalStorage('productsArray', [])
    const [formattedPhone, setFormattedPhone] = useLocalStorage('phoneNumber', '')

    const handleChange = (id, value) => {
        setOrder({...order, [id]: value});
    }

    const completed = () => {
        setOrder({...defaultOrder})
        setProductsArray([])
        setFormattedPhone('')
        setCompletedOrder(true)
    }

    const create = () => {
        createOrder(order)
            .then(completed)
            .catch(alert)
    }

    return (
        <Grid>
            <OrderAlert completedOrder={completedOrder} setCompletedOrder={setCompletedOrder}/>

            <Stack
                direction={'row'}
                spacing={3}
                px={2}
                py={4}
                justifyContent='flex-start'
                alignItems='center'
            >
                <Button startIcon={<ChevronLeftIcon/>} onClick={() => navigate('/')}>Go Back</Button>
                <Typography variant={'h4'}>Order Form</Typography>
            </Stack>

            <Grid item>
                <Grid container sx={{justifyContent: 'center'}}>
                    <Grid item sx={{width: '97%'}}>
                        <CustomerPanel
                            order={order}
                            handleChange={handleChange}
                            formattedPhone={formattedPhone}
                            setFormattedPhone={setFormattedPhone}
                        />

                        <ProductPanel
                            handleChange={handleChange}
                            productsArray={productsArray}
                            setProductsArray={setProductsArray}
                        />

                        <AcknowlPanel
                            order={order}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container py={3} sx={{justifyContent: 'center'}}>
                    <NotesInput order={order} handleChange={handleChange} />
                </Grid>
            </Grid>

            <Grid item>
                <Grid container py={5} sx={{justifyContent: 'center'}}>
                    {(order?.returnPolicy ?? false) &&
                        <Button variant='contained' sx={{width: '80%'}} onClick={create}>Create Order</Button>}
                    {(!order?.returnPolicy ?? false) &&
                        <Button variant='contained' sx={{width: '80%'}} disabled>Create Order</Button>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrderForm;