import dayjs from 'dayjs';

export const createOrder = async order => {

  const currentDate = new Date().toISOString();

  const item = {
    orderDate: dayjs(currentDate).format('YYYY-MM-DDTHH:mm:ss'),
    fulfilled: false,
    ...order
  };

  try {
    const response = await fetch('https://api-develop.theshirtshop.tools/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });

    return await response.json();
  } catch (error) {
    alert(error.message);
  }
};

export const queryOrders = async (startDate, endDate) => {

  try {
    const response = await fetch(`https://api-develop.theshirtshop.tools/orders?startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return await response.json();
  } catch (error) {
    alert(error.message);
  }
};

export const getItem = async (orderKey) => {

  try {
    const response = await fetch(`https://api-develop.theshirtshop.tools/orders/${orderKey}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return await response.json();

  } catch (error) {
    alert(error.message);
  }
};