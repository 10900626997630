import * as React from "react"
import {Stack, Box, AccordionSummary, Accordion, AccordionDetails, Divider, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, Link, FormHelperText} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PolicyDialog from "./policy-dialog";

const AcknowlPanel = ({order, handleChange}) => {

    const [open, setOpen] = React.useState(false)
    const [policy, setPolicy] = React.useState('')
    const handleClickOpen = (option) => {
        setOpen(true)
        setPolicy(option)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            {open &&
                <PolicyDialog
                    open={open}
                    handleClose={handleClose}
                    policy={policy}
                />
            }
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="custAck-header"
                >
                    Acknowledgement
                </AccordionSummary>
                <Divider/>
                <AccordionDetails>
                    <Box sx={{ display: 'flex' }}>
                        <FormControl variant='standard' component='fieldset'>
                            <FormLabel component='legend'>Read and agree to the following:</FormLabel>
                            <FormGroup>
                                <Stack spacing={2} py={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={order?.returnPolicy ?? false} onChange={e => {
                                                handleChange(e.target.name, e.target.checked)
                                            }} name='returnPolicy' />
                                        }
                                        label={<Link href="#" onClick={() => handleClickOpen('return')}>Return Policy</Link>}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={order?.textPolicy ?? false} onChange={e => {
                                                handleChange(e.target.name, e.target.checked)
                                            }} name='textPolicy' />
                                        }
                                        label={<Link href="#" onClick={() => handleClickOpen('texting')}>Texting Policy</Link>}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormHelperText>If a customer does not agree to the Texting Policy, provide more details in <em>Additional Notes</em>.</FormHelperText>
                        </FormControl>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default AcknowlPanel