import {useNavigate} from "react-router";
import {Grid, Card, CardActionArea, CardContent, Typography} from "@mui/material";

export default function HomeBtns() {
    const navigate = useNavigate();

    return (
        <Grid py={2} px={2}>
            <Grid item py={3}>
                <Card>
                    <CardActionArea onClick={() => navigate('/orders/create')}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" sx={{display: 'flex', justifyContent: 'center'}}>
                                Custom Order Form
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item py={3}>
                <Card>
                    <CardActionArea onClick={() => navigate('/orders')}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" sx={{display: 'flex', justifyContent: 'center'}}>
                                View All Orders
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    )
}