import * as React from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {useEffect, useState} from "react";

const NotesInput = ({order, handleChange}) => {

    return (
        <TextField
            id='notes'
            label='Additional notes'
            multiline
            rows={3}
            sx={{width: '75%'}}
            value={order?.notes ?? ''}
            inputProps={{maxLength: 250}}
            onChange={e => handleChange(e.target.id, e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => handleChange('notes', '')}
                            edge="end"
                            disableRipple
                        >
                            {(order?.notes ?? '' !== '') && <ClearIcon/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default NotesInput