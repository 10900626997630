import * as React from "react";
import {Alert, Snackbar} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

const OrderAlert = ({completedOrder, setCompletedOrder}) => {

    const handleClose = (reason) => {
        if (reason === 'clickaway') return

        setCompletedOrder(false)
    }

    return (
            <Snackbar
                open={completedOrder}
                autoHideDuration={15000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} icon={<CheckIcon fontSize="inherit" />} severity="success">
                    The order has been processed!
                </Alert>
            </Snackbar>
    )
}

export default OrderAlert