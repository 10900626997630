import * as React from "react";
import {
    Grid, FormControl, InputLabel, Input, Select, MenuItem
} from "@mui/material";
import {useEffect, useState} from "react";

const LogoSelectForm = ({logoObj, handleLogoChange}) => {

    return (
        <Grid container px={1} py={1}>
            <Grid item xs={12}>
                <FormControl variant='standard' sx={{width: '50%'}}>
                    <InputLabel htmlFor='logo-select'>
                        Logo
                    </InputLabel>
                    <Select
                        id='logo-select'
                        value={logoObj?.logo ?? ''}
                        defaultValue={logoObj?.logo ?? ''}
                        onChange={e => {
                            handleLogoChange('logo', e.target.value)
                        }}
                    >
                        <MenuItem value={'A'}>A</MenuItem>
                        <MenuItem value={'Tide'}>Tide</MenuItem>
                        <MenuItem value={'Vault'}>Vault</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl variant='standard' sx={{width: '50%'}}>
                    <InputLabel htmlFor='location-select'>
                        Location
                    </InputLabel>
                    <Select
                        id='location-select'
                        value={logoObj?.location ?? ''}
                        defaultValue={logoObj?.location ?? ''}
                        onChange={e => {
                            handleLogoChange('location', e.target.value)
                        }}
                    >
                        <MenuItem value={'n/a'}><em>n/a</em></MenuItem>
                        <MenuItem value={'chest'}>Chest</MenuItem>
                        <MenuItem value={'sleeve'}>Sleeve</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl variant='standard' sx={{width: '50%'}}>
                    <InputLabel htmlFor='color-select'>
                        Color
                    </InputLabel>
                    <Select
                        id='color-select'
                        value={logoObj?.color ?? ''}
                        defaultValue={logoObj?.color ?? ''}
                        onChange={e => {
                            handleLogoChange('color', e.target.value)
                        }}
                    >
                        <MenuItem value={'n/a'}><em>n/a</em></MenuItem>
                        <MenuItem value={'crimson'}>Crimson</MenuItem>
                        <MenuItem value={'white'}>White</MenuItem>
                        <MenuItem value={'black'}>Black</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default LogoSelectForm