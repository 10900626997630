import HomeAppBar from "./components/HomeBar";
import HomeBtns from "./components/HomeButtons";
import Box from "@mui/material/Box";

const Home = () => {

  return (
    <div>
        <Box>
            <HomeAppBar />
        </Box>
        <Box>
            <HomeBtns />
        </Box>
    </div>
  )
}

export default Home;